function Services() {
  return (
    <section className="py-24 md:py-28 bg-gray-100" id="services">
      <div className="px-8 md:px-40">
        <h2 className="text-center text-gray-600 text-4xl md:text-5xl">
          Services &amp; Skills
        </h2>
        <div className="mt-16 max-w-3xl mx-auto">
          <h3 className="text-xl md:text-2xl">My Approach</h3><br />

          <p className="text-lg md:text-xl">I produce web-based applications - including developing the user interfaces, building the functionality and creating database structures. My role is to help my client achieve the outcome they need, and just as importantly, to do this within the constraints of the project. This requires a curious mind, the ability to think outside of the box in order to innovate, and an acceptance that the longer-term relationship and achieving key objectives is often more important than the shorter term timesheet. Although a cliché, I work just as well individually as within a team - however, I prefer the ability to work within teams as I enjoy the experience of working problems collectively, whilst harnessing individual creativity to identify the best solutions.</p>
          <p className="text-lg md:text-xl">If this sounds like an approach that would fit with your culture and organisation, please <a href="mailto:tom@twcdigital.ltd" class="text-teal-600">get in touch</a>!</p><br />
          <h3 className="text-xl md:text-2xl">My core coding languages and frameworks:</h3><br />
          <p className="text-lg md:text-xl italic pl-5">Ruby - Ruby on Rails - Python - MySQL - JavaScript - VueJS - PHP - HTML5 - CSS3 - SASS</p>
          <h3 className="text-xl md:text-2xl">More frameworks and libraries I've used (but not as many projects):</h3><br />
          <p className="text-lg md:text-xl italic pl-5">ReactJS - NodeJS - Express</p>
          <h3 className="text-xl md:text-2xl">Other technologies I am familiar with include:</h3><br />
          <p className="text-lg md:text-xl italic pl-5 mb-10">NPM - Pandas - Git - REST APIs - JSON - Tailwind CSS</p>
          <p className="text-lg md:text-xl">I really enjoy learning new languages, frameworks and technologies, and keeping my skills sharp and up-to-date. I feel that once you know the basics of programming, picking up new ones is much easier. Whenever I start a new project, thinking about which technology will best solve the problem is the first thing I do. So whilst these are the languages, frameworks and technologies I know best, I certainly do not limit myself to them.</p>
          <p className="text-lg md:text-xl">I am available for freelance and contract positions from the 21st November 2022.</p>
        </div>
      </div>
    </section>
  )
}

export default Services;