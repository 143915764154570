function Contact() {
  return (
    <section className="py-24 md:py-28 bg-gray-100" id="contact">
      <div className="px-8 md:px-40">
        <h2 className="text-center text-gray-600 text-4xl md:text-5xl">
          Contact
        </h2>
        <div className="mt-16 max-w-3xl mx-auto text-center">
          <p className="text-lg md:text-xl">Thank you for visiting this site!</p>
          <p className="text-lg md:text-xl"><a href="mailto:tom@twcdigital.ltd" class="text-teal-600">Please get in touch</a> if you wish to discuss what I can do for you further.</p>
          <p className="text-lg md:text-xl">An up-to-date CV is available upon request.</p>
          <p className="text-lg md:text-xl">I look forward to hearing from you!</p>
        </div>
      </div>
    </section>
  )
}

export default Contact;