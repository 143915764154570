function romanize (num) {
  if (isNaN(num)) {
    return NaN;
  }
  var digits = String(+num).split(""),
      key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
             "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
             "","I","II","III","IV","V","VI","VII","VIII","IX"],
      roman = "",
      i = 3;
  while (i--) {
    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
  }
  return Array(+digits.join("") + 1).join("M") + roman;
}

function Footer() {
  return (
    <footer className="text-center py-10 px-8 md:px-40 max-w-md md:max-w-2xl mx-auto">
      <p className="text-sm social-logo"><a href="https://uk.linkedin.com/in/tom-caldwell-26b8b0119" target="_blank" rel="noopener noreferrer"><img src="/linkedin-logo.png" alt="linkedin logo" /></a></p>
      <p className="text-sm social-logo">&copy; {romanize(new Date().getFullYear())} TWC Digital Ltd</p>
      <p className="text-sm">TWC Digital is a registered Limited company in England and Wales with company number: 14241309</p>
    </footer>
  )
}

export default Footer;