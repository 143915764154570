import Header from './Header.js';
import Footer from './Footer.js';
import About from './components/About.js';
import Portfolio from './components/Portfolio.js';
import Services from './components/Services.js';
import Contact from './components/Contact.js';

function App() {
  return (
    <div>
      <Header />
      <div>
        <About />
        <Services />
        <Portfolio />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
