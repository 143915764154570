function About() {
  return (
    <section className="py-24 md:py-28" id="about">
      <div className="px-8 md:px-40">
        <h2 className="text-center text-gray-600 text-4xl md:text-5xl">
          Tom Caldwell
        </h2>
        <h2 className="text-center text-gray-600 text-3xl md:text-4xl mt-5">
          Software Developer
        </h2>
        <div className="mt-16 max-w-3xl mx-auto">
          <p className="text-lg md:text-xl">I'm Tom, a freelance and contract (outside IR35) software developer based in London.</p>
          <p className="text-lg md:text-xl">With my full-time experience working in software development for a number of companies, I use my skills and knowledge to help organisations - both large and small – create applications and websites that solve problems, or grow their business.</p>
          <p className="text-lg md:text-xl">While being passionate about my work, my aims and values are simple:</p>
          <ul class="indent text-lg md:text-xl">
            <li class="list-disc italic">To work closely with my clients in a transparent and honest manner</li>
            <li class="list-disc italic">To invest as much time as is needed to research and understand the project</li>
            <li class="list-disc italic">To innovate and deliver the project, aligned with business priorities, and within the structure required</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default About;