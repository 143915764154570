function Services() {
  return (
    <section className="py-24 md:py-28" id="portfolio">
      <div className="px-8 md:px-40">
        <h2 className="text-center text-gray-600 text-4xl md:text-5xl">
          Portfolio
        </h2>
        <div className="mt-16">
          <p className="text-lg md:text-xl max-w-3xl mx-auto">Most of my work can't be displayed, either for legal reasons, or because it involves the back-end of software or databases. Below summarises some key projects I've been involved with recently:</p><br />

          <div className="flex flex-col gap-5">
            <div className="p-10 border border-gray-300 shadow-lg mb-10">
              <h3 className="text-xl md:text-2xl text-center">Technical Analysis Software</h3><br />
              <p className="text-lg md:text-xl">For the past two years, I have worked for company where we built a platform for analysing financial stocks using technical analysis.</p>
              <p className="text-lg md:text-xl">Key points from project:</p>
              <p className="text-lg md:text-xl">
                <ul class="indent">
                  <li class="list-disc">Using Ruby, Python and MySQL to build the core project</li>
                  <li class="list-disc">Using Python Pandas for data analysis</li>
                  <li class="list-disc">Using Vue JS and Ruby on Rails to modify the existing platform - this meant getting to grips with a large new codebase</li>
                  <li class="list-disc">Using multiple different API endpoints to collect data, clean it and then store in database</li>
                  <li class="list-disc">Managing a large amount of data in MySQL - including tables wih over 20 million rows. This meant understanding and implementing solutions to read and update data very quickly, in order for it to be presented back to the user in a reasonable timeframe</li>
                  <li class="list-disc">Using Git extensively in order to collaborate with the other developers on the team - particularly for branches, code reviews and dealing with merge conflicts</li>
                </ul>
              </p>
            </div>

            <div className="p-10 border border-gray-300 shadow-lg mb-10">
              <h3 className="text-xl md:text-2xl text-center">Winchester College Website</h3><br />
              <p className="text-lg md:text-xl">Previously, I worked for an agency where I was part of a team to build a brand new website for <a href="https://www.winchestercollege.org/" target="_blank" rel="noopener noreferrer" class="text-teal-600">Winchester College</a>.</p>
              <p className="text-lg md:text-xl">Key points from project:</p>
              <p className="text-lg md:text-xl">
                <ul class="indent">
                  <li class="list-disc">Using the PHP framework Laravel along with Vue JS and MySQL to build the site</li>
                  <li class="list-disc">Working on both front and back-ends of the application</li>
                  <li class="list-disc">Understanding and modifying a pre-built CMS</li>
                  <li class="list-disc">Working with other developers for the project as well as collaborating with designers, project managers etc.</li>
                </ul>
              </p>
            </div>

            <div className="p-10 border border-gray-300 shadow-lg mb-10">
              <h3 className="text-xl md:text-2xl text-center">Castle Fine Art Website</h3><br />
              <p className="text-lg md:text-xl">Working for the same agency, I was also involved in building a new website for <a href="https://www.castlefineart.com/" target="_blank" rel="noopener noreferrer" class="text-teal-600">Castle Fine Art</a>.</p>
              <p className="text-lg md:text-xl">Key points from project:</p>
              <p className="text-lg md:text-xl">
                <ul class="indent">
                  <li class="list-disc">Also using PHP framework Laravel along with Vue JS and MySQL to build the site</li>
                  <li class="list-disc">Working on both front and back-ends of the application</li>
                </ul>
              </p>
            </div>
          </div>

          <p className="text-lg md:text-xl max-w-3xl mx-auto">For some older websites and apps I built, please view on my <a href="https://tomcaldwell.uk/projects" target="_blank" rel="noopener noreferrer" class="text-teal-600">personal site</a>.</p>
        </div>
      </div>
    </section>
  )
}

export default Services;