import $ from 'jquery';

$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault();

  $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
  }, 500);
});

function Header() {
  return (
    <header className="bg-gray-100">
      <div className="px-8 md:px-40 py-5 flex justify-between items-center">
        <div className="">
          <a href="/"><img src="/Update-logo.png" alt="twc digital logo" width={150} height={150} /></a>
        </div>
        <div className="hidden lg:block">
          <ul className="flex flex-row text-2xl">
            <li className="mr-5"><a href="#about">About</a></li>
            <li className="mr-5"><a href="#services">Services</a></li>
            <li className="mr-5"><a href="#portfolio">Portfolio</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header;